@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

/* @font-face {
    font-family: "InterRegular";
    src: local("InterRegular"),
     url("./fonts/Inter/Inter-Regular.ttf") format("truetype");
    font-weight: 400;
}
@font-face {
  font-family: "InterMedium";
  src: local("InterMedium"),
   url("./fonts/Inter/Inter-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
      font-family: "InterSemiBold";
      src: local("InterSemiBold"),
       url("./fonts/Inter/Inter-SemiBold.ttf") format("truetype");
      font-weight: 600;
} */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #150035;
}

/* body.preload *{
  animation-duration: 0s !important;
} */
canvas {
  width: 600px;
  height: 600px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  font-family: "Nuckle-Bold", Helvetica;
  margin: 0;
  color: #FFFFFF;
}

button {
  cursor: pointer;
  border: none;
  background: none;
  padding: 0px;
}

input,
textarea,
button,
a {
  font-family: "Nuckle-Bold", Helvetica;
  -webkit-tap-highlight-color: transparent;
}

:root {
  --dark: rgba(21, 0, 53, 1);
  --light: rgba(245, 245, 245, 1);
  --orange: rgba(255, 157, 0, 1);
  --white: rgba(255, 255, 255, 1);
}

.main-container {
  display: flex;
  height: 100vh;
}

.bg {
  position: fixed;
  z-index: -1;
  top: -50%;
  left: -50%;
  right: -50%;
  bottom: -50%;
  width: 200%;
  height: 200vh;
  background: transparent url('./assets/img/noise.png') repeat 0 0;
  background-repeat: repeat;
  animation: bg-animation .5s infinite;
  opacity: .9;
  visibility: visible;
}

@keyframes bg-animation {
  0% {
    transform: translate(0, 0)
  }

  10% {
    transform: translate(-5%, -5%)
  }

  20% {
    transform: translate(-10%, 5%)
  }

  30% {
    transform: translate(5%, -10%)
  }

  40% {
    transform: translate(-5%, 15%)
  }

  50% {
    transform: translate(-10%, 5%)
  }

  60% {
    transform: translate(15%, 0)
  }

  70% {
    transform: translate(0, 10%)
  }

  80% {
    transform: translate(-15%, 0)
  }

  90% {
    transform: translate(10%, 5%)
  }

  100% {
    transform: translate(5%, 0)
  }
}